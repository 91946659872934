import * as React from "react"
const DiscordIcon = ({
    color = "#ffffff",
    size = 30,
    ...props
}) => {
    const pahtScale = 30 / size;
    return <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            transform={`scale(${1 / pahtScale})`}
            d="M24.74 5.802a.07.07 0 0 0-.037-.034 23.098 23.098 0 0 0-5.7-1.767.086.086 0 0 0-.09.044 16.1 16.1 0 0 0-.71 1.457 21.326 21.326 0 0 0-6.401 0 14.742 14.742 0 0 0-.721-1.457.09.09 0 0 0-.092-.044c-1.968.34-3.884.934-5.7 1.767a.082.082 0 0 0-.037.033C1.622 11.22.628 16.509 1.116 21.73a.096.096 0 0 0 .036.066 23.223 23.223 0 0 0 6.992 3.532.09.09 0 0 0 .098-.032 16.577 16.577 0 0 0 1.43-2.326.088.088 0 0 0-.048-.123 15.3 15.3 0 0 1-2.184-1.04.09.09 0 0 1-.01-.15c.148-.11.294-.224.435-.34a.086.086 0 0 1 .09-.011c4.582 2.091 9.543 2.091 14.072 0a.086.086 0 0 1 .091.01c.14.116.287.232.435.342a.09.09 0 0 1-.008.149c-.698.407-1.429.755-2.185 1.04a.091.091 0 0 0-.052.05.089.089 0 0 0 .005.073c.418.81.896 1.586 1.429 2.325a.089.089 0 0 0 .098.033 23.147 23.147 0 0 0 7.003-3.532.09.09 0 0 0 .036-.065c.584-6.037-.977-11.281-4.14-15.93Zm-14.383 12.75c-1.38 0-2.517-1.267-2.517-2.821 0-1.555 1.115-2.821 2.517-2.821 1.412 0 2.538 1.277 2.516 2.82 0 1.555-1.115 2.822-2.516 2.822Zm9.304 0c-1.38 0-2.517-1.267-2.517-2.821 0-1.555 1.115-2.821 2.517-2.821 1.412 0 2.538 1.277 2.516 2.82 0 1.555-1.104 2.822-2.516 2.822Z"
        />
    </svg>
}
export default DiscordIcon