//import "../../../../src/fonts/style.css"
export const colors = {
    "primary":                   "#EF4126",
    "body":                      "#f5f5f5",
    "constrastBody":             "#2a2a2a",
    "layer1":                    "#021524",
    "layer2":                    "#1F1F1F",
    "layer3":                    "#292929",
    "panel":                     "#1F1F1F",
    "orange":                    "#EF4126",
    "warning":                   "#EBD147",
    "danger":                    "#EB7E47",
    "success":                   "#7EEB47",
    "accent":                    "#EB4747",
    "attention":                 "#EB477E",
    "info":                      "#4799EB",
    "seperator":                 "#333333",
    "footer":                    "#9C2707",
    "hideBody":                  "hsl(0, 0%, 40%)",
    "gray0":                     "hsl(0, 0%, 100%)",
    "gray10":                    "hsl(0, 0%, 90%)",
    "gray20":                    "hsl(0, 0%, 80%)",
    "gray30":                    "hsl(0, 0%, 70%)",
    "gray40":                    "hsl(0, 0%, 60%)",
    "gray50":                    "hsl(0, 0%, 50%)",
    "gray60":                    "hsl(0, 0%, 40%)",
    "gray70":                    "hsl(0, 0%, 30%)",
    "gray80":                    "hsl(0, 0%, 20%)",
    "gray90":                    "hsl(0, 0%, 18%)",
    "gray92":                    "hsl(0, 0%, 16%)",
    "gray94":                    "hsl(0, 0%, 14%)",
    "gray96":                    "hsl(0, 0%, 12%)",
    "gray98":                    "hsl(0, 0%, 10%)",
    "gray100":                   "hsl(0, 0%, 8%)",
    "modalBackground":           "rgba(0, 0, 0, 0.5)",
    "backgroundColor":           "#021524",
    "footerBackground":          "#9C2707",
    "inputAreaColor":            "#0B3F65",
    "aboutUsBox1":               "#0B3F65",
    "aboutUsBox2":               "#021524",
    "aboutUsBox3":               "#042137",
    "aboutUsBox4":               "#094F82",
    "sponsorsBackground":        "#002039",

};

export const typography = {
    link: {
        fontFamily: "Quicksand",
        fontSize: "30px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "normal"
    },
    header1: {
        fontFamily: "Quicksand-Light",
        fontWeight: 300,
        lineHeight: 1.3,
        fontSize: 48
    },
    header2: {
        fontFamily: "Quicksand-Regular",
        fontWeight: 400,
        lineHeight: 1.3,
        fontSize: 32
    },
    header3: {
        fontFamily: "Quicksand-Medium",
        fontWeight: 500,
        lineHeight: 1.3,
        fontSize: 26
    },
    header4: {
        fontFamily: "Quicksand-Regular",
        fontWeight: 400,
        lineHeight: 1.3,
        fontSize: 22
    },
    header5: {
        fontFamily: "Quicksand-SemiBold",
        fontWeight: 600,
        lineHeight: 1.3,
        fontSize: 22
    },
    header6: {
        fontFamily: "Quicksand-Regular",
        fontWeight: 400,
        lineHeight: 1.3,
        fontSize: 18
    },
    header7: {
        fontFamily: "Quicksand-Medium",
        fontWeight: 500,
        lineHeight: 1.3,
        fontSize: 16
    },
    header8: {
        fontFamily: "Quicksand-SemiBold",
        fontWeight: 600,
        lineHeight: 1.3,
        fontSize: 14
    },
    header9: {
        fontFamily: "Quicksand-SemiBold",
        fontWeight: 600,
        lineHeight: 1.3,
        fontSize: 12
    },
    body: {
        fontFamily: "Quicksand-Regular",
        fontWeight: 400,
        lineHeight: 1.42,
        fontSize: 14
    },
    caption: {
        fontFamily: "Quicksand-SemiBold",
        fontWeight: 600,
        letterSpacing: -0.5,
        lineHeight: 1.2,
        fontSize: 10
    },
    buttonSmall: {
        fontFamily: "Quicksand-SemiBold",
        fontWeight: 600,
        letterSpacing: 0.5,
        lineHeight: 1.40,
        fontSize: 12
    },
    buttonMedium: {
        fontFamily: "Quicksand-SemiBold",
        fontWeight: 600,
        letterSpacing: 0.5,
        lineHeight: 1.40,
        fontSize: 14
    },
    buttonLarge: {
        fontFamily: "Quicksand-SemiBold",
        fontWeight: 600,
        letterSpacing: 0.5,
        lineHeight: 1.40,
        fontSize: 16
    },
    overline: {
        fontFamily: "Quicksand-Regular",
        fontWeight: 400,
        textTransform: "uppercase",
        letterSpacing: 1,
        fontSize: 12
    },
    header10: {
        fontFamily: "Quicksand-Regular",
        fontWeight: 400,
        letterSpacing: 1,
        fontSize: 25
    },
    header11: {
        fontFamily: "Quicksand-Bold",
        letterSpacing: 1,
        fontSize: 75
    },
    header12: {
        fontFamily: "Quicksand-Light",
        letterSpacing: 1,
        fontSize: 75
    },
    header13: {
        fontFamily: "Quicksand-Medium",
        letterSpacing: 1,
        fontSize: 60
    },
    header14: {
        fontFamily: "Quicksand-Bold",
        letterSpacing: 1,
        fontSize: 30
    },
    header15: {
        fontFamily: "Quicksand-Regular",
        letterSpacing: 1,
        fontSize: 16
    },
    title: {
        fontFamily: "Quicksand-Medium",
        fontSize: " 35px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "normal",
    },
    copyright: {
        fontFamily: "Quicksand-Regular",
        fontSize: "13px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "normal",
    },
    header16:{
        fontFamily: "Quicksand-SemiBold",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "normal",
    }
};

const theme = {
    key: "dark",
    typography,
    colors
};

export default theme;
