import { createUseStyles } from "react-jss";
const useStyles = createUseStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        alignSelf: "stretch",
    },
    activityscontainer: {

    },
    activity: {
        borderRadius: "20px",
        overflow: "hidden",
    }
})
export default useStyles;
