import * as React from "react"
const InstagramIcon = ({
    color = "#ffffff",
    size = 30,
    ...props
}) => {
    const pahtScale = 30 / size;
    return <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            transform={`scale(${1 / pahtScale})`}
            d="M15.003 8.086c-3.973 0-7.177 3.087-7.177 6.913 0 3.827 3.204 6.913 7.177 6.913 3.973 0 7.177-3.086 7.177-6.913 0-3.826-3.204-6.913-7.177-6.913Zm0 11.407c-2.567 0-4.666-2.015-4.666-4.494 0-2.479 2.093-4.494 4.666-4.494 2.574 0 4.666 2.015 4.666 4.494 0 2.479-2.099 4.494-4.666 4.494Zm9.145-11.69c0 .897-.75 1.613-1.674 1.613-.93 0-1.674-.722-1.674-1.613 0-.89.75-1.612 1.674-1.612.924 0 1.674.722 1.674 1.612Zm4.754 1.637c-.107-2.16-.619-4.073-2.262-5.65-1.636-1.576-3.623-2.07-5.865-2.178-2.311-.126-9.239-.126-11.55 0-2.236.103-4.223.596-5.865 2.172-1.643 1.577-2.15 3.49-2.262 5.65-.13 2.226-.13 8.898 0 11.124.107 2.16.619 4.073 2.262 5.65 1.642 1.576 3.623 2.07 5.865 2.178 2.311.126 9.239.126 11.55 0 2.242-.102 4.229-.596 5.865-2.178 1.637-1.576 2.15-3.49 2.262-5.65.13-2.226.13-8.892 0-11.118Zm-2.986 13.507c-.487 1.18-1.43 2.088-2.661 2.563-1.843.704-6.215.541-8.252.541-2.036 0-6.415.157-8.252-.541a4.64 4.64 0 0 1-2.66-2.563c-.731-1.775-.563-5.987-.563-7.948 0-1.961-.162-6.179.562-7.948.488-1.179 1.43-2.087 2.661-2.563 1.843-.704 6.216-.541 8.252-.541 2.037 0 6.415-.157 8.252.541a4.64 4.64 0 0 1 2.66 2.563c.732 1.775.563 5.987.563 7.948 0 1.961.169 6.179-.562 7.948Z"
        />
    </svg>
}
export default InstagramIcon
